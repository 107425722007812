// @flow strict
import React from 'react';
import cls from 'classnames';
import sortDirection, { type TSortDirection } from '../../enums/sort-direction';
import IconTriangleDown from '../icon-triangle-down';
import IconTriangleUp from '../icon-triangle-up';
import styles from './styles.css';

export type TSorter = {|
    text: string,
    direction?: TSortDirection,
    isActive?: boolean,
    onClick?: () => void
|};

const Sorter = ({ text, direction, isActive, onClick }: TSorter) => (
    <button type="button" className={cls(styles.button, isActive && styles.isActive)} onClick={onClick}>
        <span>{ text }</span>
        { direction === sortDirection.ASC && <IconTriangleDown className={cls(styles.icon, !isActive && styles.muted)} />}
        { direction === sortDirection.DESC && <IconTriangleUp className={cls(styles.icon, !isActive && styles.muted)} />}
    </button>
);

Sorter.defaultProps = {
    isActive: false,
    direction: sortDirection.ASC,
    onClick() {}
};

export default Sorter;
