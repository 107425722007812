// @flow strict
export type TRawValue = mixed;

function valueToString(value: ?TRawValue): string {
    // eslint-disable-next-line no-undefined
    if (value === null || value === undefined) {
        return '';
    }

    return String(value);
}

export default valueToString;
