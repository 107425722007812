// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconCross = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polygon points="14 4.939 15.061 6 11.06 9.999 15.061 14 14 15.061 9.999 11.06 6 15.061 4.939 14 8.939 9.999 4.939 6 6 4.939 9.999 8.939" />
    </svg>
);

IconCross.defaultProps = {
    size: 20
};

export default IconCross;
