// @flow strict
const scheduleGroupState = {
    READY: 'Ready',
    IN_PROGRESS: 'InProgress',
    WAITING_REWARDING: 'WaitingRewarding',
    COMPLETED: 'Completed'
};

export type TScheduleGroupState = $Values<typeof scheduleGroupState>;

export default scheduleGroupState;
