// @flow strict
import React, { useEffect, useState } from "react";
import useScheduleApi from "../../hooks/use-schedule-api";
import { CompetitionDetails, Day, Tabs, Select } from "../../components";
import { ScheduleByGroupList } from "../../components/schedule-by-group";
import { ScheduleByRoundList } from "../../components/schedule-by-round";
import Toggle from "../../components/toggle";
import styles from "./styles.css";

export type TSchedulePage = {|
    match: ?{|
        params: ?{|
            competitionId: ?string,
        |},
    |},
|};

const SchedulePage = ({ match }: TSchedulePage) => {
    const [schedule, isFetching, error, fetch] = useScheduleApi();
    const [arePastRoundsHidden, setArePastRoundsHidden] = useState(false);
    const [selectedHall, setHall] = useState(false);

    const competitionId = parseInt(match?.params?.competitionId ?? "", 10);
    const heading = <h1 className={styles.heading}>Расписание</h1>;

    useEffect(() => {
        document.title = `Расписание · ${document.title}`;
    }, []);

    useEffect(() => {
        if (schedule.halls.length > 0) {
            setHall(schedule.halls[0]);
        }
    }, [schedule]);

    useEffect(() => {
        fetch(competitionId);
    }, [competitionId]);

    if (isFetching) {
        return (
            <>
                {heading}
                <h3>Загрузка...</h3>
            </>
        );
    }

    if (error.length > 0) {
        return (
            <>
                {heading}
                <h3>{error}</h3>
            </>
        );
    }

    const scheduleByGroup = (
        <ScheduleByGroupList
            className={styles.tabContent}
            competitionId={competitionId}
            items={schedule.groups}
        />
    );

    let currentDayIndex = schedule.days.findIndex((x) => x.isCurrent);

    if (currentDayIndex === -1) {
        currentDayIndex = 0;
    }

    const scheduleByRound = (
        <Tabs activeTab={`schedule-day-${currentDayIndex}`}>
            {schedule.days.map((day, index) => (
                <Tabs.Tab
                    name={`schedule-day-${index}`}
                    key={`schedule-day-${index}`}
                    component={
                        <Day
                            date={day.date}
                            withMonth={index === 0 || day.date.getDate() === 1}
                        />
                    }
                >
                    <ScheduleByRoundList
                        competitionId={competitionId}
                        items={day.items}
                        index={index}
                        arePastRoundsHidden={arePastRoundsHidden}
                        selectedHall={selectedHall}
                    />
                </Tabs.Tab>
            ))}
        </Tabs>
    );

    return (
        <>
            {heading}
            {schedule.details !== null && (
                <CompetitionDetails {...schedule.details} />
            )}
            {schedule.groups.length > 0 && schedule.days.length > 0 && (
                <Tabs activeTab="byGroup" className={styles.tabs}>
                    <Tabs.Tab
                        name="byGroup"
                        component={
                            <h3 className={styles.tabHeading}>По группам</h3>
                        }
                    >
                        {scheduleByGroup}
                    </Tabs.Tab>
                    <Tabs.Tab
                        name="byRound"
                        component={
                            <h3 className={styles.tabHeading}>По турам</h3>
                        }
                    >
                        <div className={styles.tabContent}>
                            <div className={styles.tabContentTopBar}>
                                {schedule.halls.length > 1 && (
                                    <Select
                                        className={styles.hallSelect}
                                        options={schedule.halls}
                                        onHandleChange={(id) =>
                                            setHall(
                                                schedule.halls.find(
                                                    (x) => x.id === Number(id)
                                                )
                                            )
                                        }
                                    />
                                )}
                                <Toggle
                                    name="hide-past-rounds"
                                    label="Скрывать завершённые туры"
                                    isChecked={arePastRoundsHidden}
                                    onChange={setArePastRoundsHidden}
                                />
                            </div>
                            {schedule.days.length > 0 ? scheduleByRound : null}
                        </div>
                    </Tabs.Tab>
                </Tabs>
            )}
            {schedule.groups.length > 0 && schedule.days.length === 0 && (
                <div className={styles.tabs}>{scheduleByGroup}</div>
            )}
            {schedule.groups.length === 0 && schedule.days.length > 0 && (
                <div className={styles.tabs}>{scheduleByRound}</div>
            )}
        </>
    );
};

export default SchedulePage;
