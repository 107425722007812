// @flow strict
import React from 'react';
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import styles from './styles.css';

export type TDay = {|
    className?: ?string,
    date: Date,
    withMonth: boolean
|};

const Day = ({ className, date, withMonth }: TDay) => {
    const day = date.getDate();
    const weekday = format(date, 'EEEE', { locale });
    const month = format(date, 'MMMM', { locale });

    return (
        <div className={className}>
            <div className={styles.date}>
                <span className={styles.day}>{ day }</span> { (withMonth || day === 1) && month }
            </div>
            <div>
                { weekday }
            </div>
        </div>
    );
};

Day.defaultProps = {
    className: null
};

export default Day;
