// @flow strict
import React from 'react';
import styles from './styles.css';

const ScheduleByGroupFooter = () => (
    <div className={styles.footer}>
        <div className={styles.group}>
            Группа
        </div>
        <div className={styles.round}>
            Текущий тур
        </div>
        <div className={styles.results}>
            Результаты
        </div>
    </div>
);

export default ScheduleByGroupFooter;
