// @flow strict
const competitorType = {
    SOLO: 'Solo',
    COUPLE: 'Couple',
    ENSEMBLE: 'Ensemble'
};

export type TCompetitorType = $Values<typeof competitorType>;

export default competitorType;
