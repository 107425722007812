// @flow strict
import React from 'react';
import cls from 'classnames';
import type { TSortDirection } from '../../enums/sort-direction';
import Sorter from '../sorter';
import styles from './styles.css';

export type TRoundHeatsFooter = {|
    dances: Array<string>,
    sortDirection: TSortDirection,
    onSort: () => void
|};

const RoundHeatsFooter = ({ dances, sortDirection, onSort }: TRoundHeatsFooter) => (
    <div className={styles.footer}>
        <div className={cls(styles.number, styles.footerCell)}>
            <Sorter text="№" direction={sortDirection} isActive onClick={onSort} />
        </div>
        <div className={cls(styles.competitors, styles.footerCell)}>
            Участники
        </div>
        { dances.map(dance => (
            <div key={`round-heats-header-dance-${dance}`} className={cls(styles.heatCell, styles.footerCell)}>
                { dance }
            </div>
        )) }
    </div>
);

export default RoundHeatsFooter;
