// @flow strict
import type { TVideos } from '../types/competition-details';
import valueToString from './value-to-string';

export type TRawVideos = Array<{
    name?: string,
    link?: string
}>;

function responseToVideos(response: ?TRawVideos): TVideos {
    // eslint-disable-next-line no-undefined
    if (response === null || response === undefined) {
        return [];
    }

    return response.map(x => ({
        name: valueToString(x.name),
        link: valueToString(x.link)
    }));
}

export default responseToVideos;
