// @flow strict
import React, { useState, useEffect } from 'react';
import filterScheduleByRound from '../../filters/filter-schedule-by-round';
import type { TScheduleByRoundItem } from '../../types/schedule';
import NotFound from '../not-found';
import roundStage from '../../enums/round-stage';
import Footer from './footer';
import Header from './header';
import Item from './item';

export type TScheduleByRoundList = {|
    className?: string,
    competitionId: number,
    items: Array<TScheduleByRoundItem>,
    index: number,
    selectedHall: {|id: number, name: string|},
    arePastRoundsHidden: boolean
|};

const filterByHall = (items, hallId) => items.filter(x => x.hallId === hallId);

const ScheduleByRoundList = ({ className, competitionId, items, index, selectedHall, arePastRoundsHidden}: TScheduleByRoundList) => {
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        if (selectedHall) {
            setQuery('');
        }
    }, [selectedHall]);

    useEffect(() => {
        let newFilteredItems = [...items];

        if (arePastRoundsHidden) {
            newFilteredItems = newFilteredItems.filter(item => item.stage !== roundStage.PAST);
        }

        if (selectedHall) {
            newFilteredItems = filterByHall(newFilteredItems, selectedHall.id);
        }

        const trimmedQuery = query.trim();

        if (trimmedQuery.length > 0 && items.length > 0) {
            newFilteredItems = filterScheduleByRound(newFilteredItems, trimmedQuery);
        }

        setFilteredItems(newFilteredItems);
    }, [query, items, selectedHall, arePastRoundsHidden]);

    const filteredByHallItems = filterByHall(items, selectedHall.id);

    if (filteredByHallItems.length === 0) {
        return (
            <div className={className}>
                <NotFound text={`${selectedHall.name} · нет соревнований`} style={{ marginTop: '1rem' }} />
            </div>
        );
    }

    if (filteredItems.length === 0) {
        return (
            <div className={className}>
                <Header query={query} index={index} onSearch={setQuery} />
                <NotFound text={query.trim().length > 0 ? 'Выступления этой группы не найдены' : 'Все туры завершены'} style={{ marginTop: '1rem' }} />
            </div>
        );
    }

    return (
        <div className={className}>
            <Header query={query} index={index} onSearch={setQuery} />
            { filteredItems.map(item => (
                <Item
                    key={`schedule-by-group-item-${item.id}`}
                    {...item}
                    competitionId={competitionId}
                />
            )) }
            <Footer />
        </div>
    );
};

ScheduleByRoundList.defaultProps = {
    className: null
};

export default ScheduleByRoundList;
