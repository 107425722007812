// @flow strict
import React from 'react';
import SearchForm from '../search-form';
import styles from './styles.css';

export type TScheduleByGroupHeader = {|
    query: string,
    onSearch: (q: string) => void
|};

const ScheduleByGroupHeader = ({ query, onSearch }: TScheduleByGroupHeader) => (
    <div className={styles.header}>
        <div className={styles.group}>
            <SearchForm
                name="schedule-by-group-search"
                label="Группа"
                query={query}
                onChange={onSearch}
                onDismiss={() => onSearch('')}
            />
        </div>
        <div className={styles.round}>
            Текущий тур
        </div>
        <div className={styles.results}>
            Результаты
        </div>
    </div>
);

export default ScheduleByGroupHeader;
