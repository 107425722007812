// @flow strict
import React from 'react';
import competitorType, { type TCompetitorType } from '../../enums/competitor-type';
import SearchForm from '../search-form';
import styles from './styles.css';

type TProps = {|
    competitorType: TCompetitorType,
    query: string,
    onSearch: (q: string) => void
|};

const GroupResultsHeader = (props: TProps) => (
    <div className={styles.header}>
        <div className={styles.place}>
            Место
        </div>
        <div className={styles.number}>
            №
        </div>
        <div className={styles.competitors}>
            <SearchForm
                name="group-results-search"
                label="Участники"
                query={props.query}
                onChange={props.onSearch}
                onDismiss={() => props.onSearch('')}
            />
        </div>
        { props.competitorType !== competitorType.ENSEMBLE && (
            <>
                <div className={styles.classHeader}>
                    Класс
                </div>
                <div className={styles.classPosition}>
                    Место в классе
                </div>
                <div className={styles.points}>
                    Очки
                </div>
            </>
        ) }
        <div className={styles.locationHeader}>
            Клуб, город
        </div>
        <div className={styles.coachesHeader}>
            Тренеры
        </div>
    </div>
);

export default GroupResultsHeader;
