// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconTriangleUp = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polygon fillRule="evenodd" points="7 12 10 8 13 12" />
    </svg>
);

IconTriangleUp.defaultProps = {
    size: 20
};

export default IconTriangleUp;
