// @flow strict
import React from 'react';
import cls from 'classnames';
import roundStage from '../../enums/round-stage';
import roundType, { labels, shortLabels } from '../../enums/round-type';
import { type TScheduleByRoundItem } from '../../types/schedule';
import Hatch from '../hatch';
import styles from './styles.css';
import roundResultsState from '../../enums/round-results-state';

type TProps = {|
    ...TScheduleByRoundItem,
    competitionId: number
|};

const ScheduleByRoundItem = ({ competitionId, startTime, finishTime, floors, description, dance, stage }: TProps) => {
    const itemClassNames = [styles.item];

    if (stage === roundStage.PAST) {
        itemClassNames.push(styles.past);
    }

    if (stage === roundStage.CURRENT) {
        itemClassNames.push(styles.current);
    }

    if (dance === null) {
        itemClassNames.push(styles.nowrap);

        return (
            <Hatch>
                <div className={cls(itemClassNames)} data-testid="ScheduleByRoundList.item.hatch">
                    <div className={styles.range}>
                        { startTime }—{finishTime}
                    </div>
                    <div className={styles.beginning}>
                        { startTime }
                    </div>
                    <div className={styles.round} />
                    <div className={styles.floors}>
                        { floors }
                    </div>
                    <div className={styles.break}>
                        { description }
                    </div>
                </div>
            </Hatch>
        );
    }

    itemClassNames.push(styles.underscore);
 
    return (
        <div className={cls(itemClassNames)} data-testid="ScheduleByRoundList.item.round">
            <div className={styles.roundContainer}>
                <div className={styles.range}>
                    { startTime }—{finishTime}
                </div>
                <div className={styles.beginning}>
                    { startTime }
                </div>
                <div className={styles.round}>
                    <strong>{ shortLabels[dance.roundType] }</strong>
                </div>
                <div className={styles.floors}>
                    { floors }
                </div>
                <div className={styles.group}>
                    <div className={styles.groupNumber}>
                        { dance.groupNumber !== null
                            ? <strong>{ dance.groupNumber }</strong>
                            : '·'
                        }
                    </div>
                    <div className={styles.groupName} data-testid="ScheduleByRoundList.item.group.name">
                        { dance.groupName }
                    </div>
                </div>
                <div className={styles.dances}>
                    { dance.dances }
                </div>
                <div className={styles.competitors}>
                    { dance.competitorCount }
                </div>
            </div>
            <div className={styles.roundTypeContainer}>
                <div className={styles.roundTypeLabel}>
                    Тур
                </div>
                <div className={styles.roundType}>
                    { labels[dance.roundType] }
                </div>
            </div>
            <div className={styles.dancesContainer}>
                <div className={styles.dancesLabel}>
                    Программа
                </div>
                <div className={styles.dancesValue}>
                    { dance.dances }
                </div>
            </div>
            <div className={styles.heatsContainer}>
                <div className={styles.heatsLabel}>
                    Заходы
                </div>
                <div className={styles.heats}>
                    { dance.heatCount > 0
                        ? (
                            <a href={`/competitions/${competitionId}/rounds/${dance.roundId}`}>
                                { dance.heatCount } по { dance.competitorsPerHeat }
                            </a>
                        )
                        : '·'
                    }
                </div>
            </div>
            <div className={styles.resultsContainer}>
                <div className={styles.resultsLabel}>
                    Результаты
                </div>
                <div className={styles.results}>
                    { dance.resultsState === roundResultsState.NONE && '·' }
                    { dance.resultsState === roundResultsState.WAITING_REWARDING && (
                        <a href={`/competitions/${competitionId}/results/${dance.groupId}`}>
                            ожидают награждения
                        </a>
                    ) }
                    { dance.resultsState === roundResultsState.COMPLETED && (
                        <a href={`/competitions/${competitionId}/results/${dance.groupId}`}>
                            { dance.roundType === roundType.FINAL
                                ? `${dance.competitorCount} награждены`
                                : `${dance.passedCount} прошли дальше`
                            }
                        </a>
                    ) }
                </div>
            </div>
        </div>
    );
};

export default ScheduleByRoundItem;
