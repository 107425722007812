// @flow strict
import React from "react";

const NotFoundPage = () => (
    <>
        <h1>Ничего нет</h1>
    </>
);

export default NotFoundPage;
