// @flow strict
import axios from 'axios';
import { useEffect, useState } from 'react';
import competitorType from '../enums/competitor-type';
import { responseToGroupResults } from '../mappers';
import type { TGroupResults } from '../types/group-results';

function getInitialState(): TGroupResults {
    return {
        groupName: '',
        groupNumber: null,
        competitorType: competitorType.COUPLE,
        rounds: []
    };
}

function useGroupResultsApi() {
    const [results, setResults] = useState(getInitialState());

    const [competitionId, setCompetitionId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');

    const url = `/api/competitions/${competitionId}/groups/${groupId}/results`;

    function setParams(cid: number, rid: number) {
        setCompetitionId(cid);
        setGroupId(rid);
    }

    useEffect(() => {
        async function fetch() {
            setIsFetching(true);

            try {
                const { data } = await axios.get(url);

                setResults(responseToGroupResults(data));
                setError('');
            } catch (err) {
                setError('Ошибка при получении результатов');
            } finally {
                setIsFetching(false);
            }
        }

        if (competitionId !== 0 && groupId !== 0) {
            fetch();
        }
    }, [competitionId, groupId]);

    return [results, isFetching, error, setParams];
}

export default useGroupResultsApi;
