// @flow strict
import axios from 'axios';
import { useEffect, useState } from 'react';
import { responseToRoundHeats } from '../mappers';
import type { TRoundHeats } from '../types/round-heats';
import roundType from '../enums/round-type';
import competitorType from '../enums/competitor-type';

function getInitialState(): TRoundHeats {
    return {
        groupName: '',
        groupNumber: null,
        roundType: roundType.ONE_256,
        competitorType: competitorType.COUPLE,
        competitors: [],
        dances: [],
        competitorCount: 0,
        competitorsPerHeat: 0,
        heatCount: 0,
        markCount: 0
    };
}

function useRoundHeatsApi() {
    const [heats, setHeats] = useState(getInitialState());

    const [competitionId, setCompetitionId] = useState(0);
    const [roundId, setRoundId] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');

    const url = `/api/competitions/${competitionId}/rounds/${roundId}/heats`;

    function setParams(cid: number, rid: number) {
        setCompetitionId(cid);
        setRoundId(rid);
    }

    useEffect(() => {
        async function fetch() {
            setIsFetching(true);

            try {
                const { data } = await axios.get(url);

                setHeats(responseToRoundHeats(data));
                setError('');
            } catch (err) {
                setError('Ошибка при получении заходов');
            } finally {
                setIsFetching(false);
            }
        }

        if (competitionId !== 0 && roundId !== 0) {
            fetch();
        }
    }, [competitionId, roundId]);

    return [heats, isFetching, error, setParams];
}

export default useRoundHeatsApi;
