// @flow strict
function escapeRegExp(query: string): string {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function createRegExp(query: string): RegExp {
    const q = escapeRegExp(query)
        .replace(/[aа]/, '(a|а)')
        .replace(/[bв]/, '(b|в)')
        .replace(/[cс]/, '(c|с)')
        .replace(/[dд]/, '(d|д)')
        .replace(/[eе]/, '(e|е)')
        .replace(/[mм]/, '(m|м)');

    return new RegExp(q, 'ig');
}

function createPredicate(query: string) {
    const terms = query
        .toLowerCase()
        .split(' ')
        .filter(x => x.length > 0);

    return (value: string): boolean => terms.some(term => createRegExp(term).test(value.toLowerCase()));
}

export default createPredicate;
