// @flow strict
import React, { useState } from 'react';
import filterGroupResults from '../../filters/filter-group-results';
import { type TCompetitorType } from '../../enums/competitor-type';
import { type TGroupResultsRound } from '../../types/group-results';
import { labels } from '../../enums/round-type';
import roundState from '../../enums/round-state';
import Collapse from '../collapse';
import Hatch from '../hatch';
import Footer from './footer';
import Header from './header';
import Item from './item';
import styles from './styles.css';

type TGroupResultsList = {|
    competitorType: TCompetitorType,
    rounds: Array<TGroupResultsRound>
|};

const GroupResultsList = ({ competitorType, rounds }: TGroupResultsList) => {
    const [query, setQuery] = useState('');

    let filteredRounds = rounds;
    const trimmedQuery = query.trim();

    if (trimmedQuery.length > 0 && rounds.length > 0) {
        filteredRounds = filterGroupResults(rounds, trimmedQuery);
    }

    let trigger = null;

    return (
        <>
            <Header competitorType={competitorType} query={query} onSearch={setQuery} />
            { filteredRounds.map(round => {
                trigger = (
                    <Hatch>
                        <div className={styles.round}>
                            <div className={styles.roundTitle}>
                                { round.roundState === roundState.RESULTS && labels[round.roundType] }
                                { round.roundState === roundState.PASSED_TO_NEXT_ROUND && `Прошли в ${labels[round.roundType]}` }
                                { round.roundState === roundState.WAITING_REWARDING && `${labels[round.roundType]} · Ожидают награждения` }
                            </div>
                            <div className={styles.roundCompetitors}>{ round.competitors.length }</div>
                        </div>
                    </Hatch>
                );

                return (
                    <div key={`group-results-round-${round.roundType}`}>
                        <Collapse trigger={trigger}>
                            { round.competitors.map(competitor => (
                                <Item
                                    key={`group-results-round-${round.roundType}-competitor-${competitor.number}`}
                                    {...competitor}
                                    competitorType={competitorType}
                                />
                            )) }
                        </Collapse>
                    </div>
                );
            }) }
            <Footer competitorType={competitorType} />
        </>
    );
};

export default GroupResultsList;
