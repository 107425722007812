// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconMusic = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M5.31,1 L13.31,3 L13.31,12 C13.4054351,12.8478851 12.8811452,13.8006717 11.9330127,14.3480762 C10.7372811,15.0384321 9.32023394,14.8226107 8.76794919,13.8660254 C8.21566444,12.9094402 8.73728114,11.5743305 9.9330127,10.8839746 C10.7352326,10.4208127 11.6370691,10.3655311 12.3105475,10.6681781 L12.30959,5.75 L6.30958999,4.25 L6.31,10 C6.40543509,10.8478851 5.88114517,11.8006717 4.9330127,12.3480762 C3.73728114,13.0384321 2.32023394,12.8226107 1.76794919,11.8660254 C1.21566444,10.9094402 1.73728114,9.57433053 2.9330127,8.8839746 C3.7352326,8.42081272 4.63706908,8.36553114 5.31054747,8.66817811 L5.31,1 Z" />
    </svg>
);

IconMusic.defaultProps = {
    size: 16
};

export default IconMusic;
