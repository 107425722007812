// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconLoupe = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path d="M12.9375,5.03710075 C14.7310973,6.07263466 15.3456292,8.36609868 14.3100953,10.159696 C13.4029043,11.7309969 11.5302732,12.397385 9.87407795,11.8421775 L7.71201905,15.5878992 L6.41298095,14.8378992 L8.5750808,11.0919678 C7.26678111,9.93517931 6.90788955,7.98069239 7.81490474,6.40969602 C8.85043864,4.61609868 11.1439027,4.00156685 12.9375,5.03710075 Z M12.1875,6.33613886 C11.1113416,5.71481851 9.73526318,6.08353761 9.11394284,7.15969602 C8.4926225,8.23585442 8.8613416,9.61193283 9.9375,10.2332532 C11.0136584,10.8545735 12.3897368,10.4858544 13.0110572,9.40969602 C13.6323775,8.33353761 13.2636584,6.9574592 12.1875,6.33613886 Z" />
    </svg>
);

IconLoupe.defaultProps = {
    size: 20
};

export default IconLoupe;
