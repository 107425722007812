// @flow strict
const roundResultsState = {
    NONE: 'None',
    WAITING_REWARDING: 'WaitingRewarding',
    COMPLETED: 'Completed'
};

export type TRoundResultsState = $Values<typeof roundResultsState>;

export default roundResultsState;
