// @flow strict
import React from 'react';
import SearchForm from '../search-form';
import styles from './styles.css';

export type TScheduleByRoundHeader = {|
    query: string,
    onSearch: (q: string) => void,
    index: number
|};

const ScheduleByRoundHeader = ({ query, onSearch, index }: TScheduleByRoundHeader) => (
    <div className={styles.header}>
        <div className={styles.range}>
            Начало—конец
        </div>
        <div className={styles.beginning}>
            Начало
        </div>
        <div className={styles.round}>
            Тур
        </div>
        <div className={styles.floors}>
            Паркет
        </div>
        <div className={styles.group}>
            <SearchForm
                name={`schedule-by-round-search-${index}`}
                label="Группа"
                query={query}
                onChange={onSearch}
                onDismiss={() => onSearch('')}
            />
        </div>
        <div className={styles.dances}>
            Программа
        </div>
        <div className={styles.competitors}>
            Участники
        </div>
        <div className={styles.heatsHeader}>
            Заходы
        </div>
        <div className={styles.resultsHeader}>
            Результаты
        </div>
    </div>
);

export default ScheduleByRoundHeader;
