// @flow strict
import React from 'react';
import cls from 'classnames';
import type { TSortDirection } from '../../enums/sort-direction';
import SearchForm from '../search-form';
import Sorter from '../sorter';
import styles from './styles.css';

export type TRoundHeatsHeader = {|
    dances: Array<string>,
    query: string,
    sortDirection: TSortDirection,
    onSearch: (q: string) => void,
    onSort: () => void
|};

const RoundHeatsHeader = ({ dances, query, sortDirection, onSearch, onSort }: TRoundHeatsHeader) => (
    <div className={styles.header}>
        <div className={cls(styles.number, styles.headerCell)}>
            <Sorter text="№" direction={sortDirection} isActive onClick={onSort} />
        </div>
        <div className={cls(styles.competitors, styles.headerCell)}>
            <SearchForm
                name="round-heats-search"
                label="Участники"
                query={query}
                onChange={onSearch}
                onDismiss={() => onSearch('')}
            />
        </div>
        { dances.map(dance => (
            <div key={`round-heats-header-dance-${dance}`} className={cls(styles.heatCell, styles.headerCell)}>
                { dance }
            </div>
        )) }
    </div>
);

export default RoundHeatsHeader;
