/* eslint-disable react/jsx-indent */
// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconChevronSelect = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polyline fill="none" stroke="#000" strokeLinecap="square" points="5 8 10 13 15 8" />
    </svg>
);

IconChevronSelect.defaultProps = {
    size: 20
};

export default IconChevronSelect;
