// @flow strict
import * as React from 'react'; // eslint-disable-line import/no-namespace
import cls from 'classnames';
import IconChevronDown from '../icon-chevron-down';
import IconChevronRight from '../icon-chevron-right';
import styles from './styles.css';

export type TCollapse = {|
    trigger: React.Node,
    children: React.Node
|};

const { useState } = React;

const Collapse = ({ trigger, children }: TCollapse) => {
    const [isOpened, setIsOpened] = useState(true);

    function handleClick() {
        setIsOpened(!isOpened);
    }

    return (
        <div>
            <button type="button" className={styles.trigger} onClick={handleClick} data-testid="collapsible-trigger">
                { trigger }
                { isOpened ? <IconChevronDown className={styles.icon} /> : <IconChevronRight className={styles.icon} />}
            </button>
            <div className={cls(styles.children, isOpened && styles.isOpened)} hidden={!isOpened} data-testid="collapsible-content">
                { children }
            </div>
        </div>
    );
};

export default Collapse;
