// @flow strict
import React from 'react';
import cls from 'classnames';
import type { TScheduleHall } from '../../types/schedule';
import IconChevronSelect from '../icon-chevron-select';
import styles from './styles.css';

export type TSelect= {|
    options: Array<TScheduleHall>,
    className: string,
    onHandleChange: (id: number) => void
|};

const SelectArena = ({ options, className, onHandleChange}: TSelect) => {
    if (options.length > 0) {
        return (
            <div className={cls([styles.container, className])}>
                <div className={styles.icon}><IconChevronSelect /></div>
                <select className={styles.select} onChange={e => onHandleChange(e.target.value)} data-testid="component:select">
                    {options.map(x => <option key={x.id} value={x.id} data-testid="component:select.option">{x.name}</option>)}
                </select>
            </div>
        );
    }

    return null;
};

export default SelectArena;
