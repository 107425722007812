// @flow strict
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { labels } from '../../enums/round-type';
import { RoundHeatsList } from '../../components/round-heats';
import useRoundHeatsApi from '../../hooks/use-round-heats-api';
import { pluralize } from '../../utilities';
import styles from './styles.css';

export type TRoundHeatsPage = {|
    match: ?{|
        params: ?{|
            competitionId: ?string,
                roundId: ?string
                    |}
    |}
|};

const RoundHeatsPage = ({ match }: TRoundHeatsPage) => {
    const [heats, isFetching, error, fetch] = useRoundHeatsApi();

    const competitionId = parseInt(match?.params?.competitionId ?? '', 10);
    const roundId = parseInt(match?.params?.roundId ?? '', 10);

    useEffect(() => {
        document.title = `Заходы · ${document.title}`;
    }, []);

    useEffect(() => {
        fetch(competitionId, roundId);
    }, [competitionId, roundId]);

    if (isFetching) {
        return (
            <>
                <Link to={`/competitions/${competitionId}/schedule`}>назад к расписанию</Link>
                <h1 className={styles.heading}>Заходы</h1>
                <h3>Загрузка...</h3>
            </>
        );
    }

    if (error.length > 0) {
        return (
            <>
                <Link to={`/competitions/${competitionId}/schedule`}>назад к расписанию</Link>
                <h1 className={styles.heading}>Заходы</h1>
                <h3>{error}</h3>
            </>
        );
    }

    return (
        <>
            <Link to={`/competitions/${competitionId}/schedule`}>назад к расписанию</Link>
            <h1 className={styles.heading}>Заходы · {labels[heats.roundType]}</h1>
            <h3 className={styles.subheading}>
                {heats.groupNumber !== null ? `${heats.groupNumber} · ${heats.groupName}` : heats.groupName}
            </h3>
            <div className={styles.summary}>
                <div className={styles.heats}>
                    {heats.heatCount} {pluralize(heats.heatCount, 'заход', 'захода', 'заходов')} по {heats.competitorsPerHeat} {pluralize(heats.competitorsPerHeat, 'участнику', 'участника', 'участников')}
                </div>
                {heats.markCount > 0 && (
                    <div>
                        в следующий тур пройдут { heats.markCount} из { heats.competitorCount} {pluralize(heats.competitorCount, 'участника', 'участников', 'участников')}
                    </div>
                )}
            </div>
            <RoundHeatsList
                dances={heats.dances}
                competitors={heats.competitors}
                competitorType={heats.competitorType}
            />
        </>
    );
};

export default RoundHeatsPage;
