// @flow strict
import type { TRoundHeatsCompetitor } from '../types/round-heats';
import createPredicate from './create-predicate';

function filterRoundHeats(competitors: Array<TRoundHeatsCompetitor>, query: string): Array<TRoundHeatsCompetitor> {
    const predicate = createPredicate(query);

    return competitors.filter(competitor => competitor.athletes.some(predicate) || (competitor.ensembleName.length > 0 && predicate(competitor.ensembleName)) || predicate(String(competitor.number)));
}

export default filterRoundHeats;
