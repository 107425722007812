// @flow strict
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Header } from './components';
import { NotFoundPage, GroupResultsPage, RoundHeatsPage, SchedulePage } from './pages';

const Routes = () => (
    <>
        <Header />
        <Container>
            <Switch>
                <Route path="/competitions/:competitionId/schedule" exact component={SchedulePage} />
                <Route path="/competitions/:competitionId/rounds/:roundId" exact component={RoundHeatsPage} />
                <Route path="/competitions/:competitionId/results/:groupId" exact component={GroupResultsPage} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Container>
    </>
);

export default Routes;
