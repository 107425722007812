// @flow strict
import React, { useState } from 'react';
import IconCross from '../icon-cross';
import IconLoupe from '../icon-loupe';
import styles from './styles.css';

export type TSearchForm = {|
    name: string,
    query: string,
    // eslint-disable-next-line react/require-default-props
    label: string,
    // eslint-disable-next-line react/require-default-props
    onChange: (q: string) => void,
    // eslint-disable-next-line react/require-default-props
    onDismiss: () => void
|};

const SearchForm = ({ name, query, label, onChange, onDismiss }: TSearchForm) => {
    const [isFocused, setIsFocused] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();
    }

    function handleChange(event) {
        onChange(event.target.value);
    }

    /* eslint-disable react/jsx-no-bind */
    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            { isFocused || query.length > 0
                ? null
                : (
                    // eslint-disable-next-line jsx-a11y/label-has-for
                    <label className={styles.label} htmlFor={name}>
                        { label.length > 0 && <span>{ label }</span> }
                        <IconLoupe />
                    </label>
                )
            }
            <input
                data-testid="search-form-input"
                id={name}
                name={name}
                className={styles.input}
                value={query}
                onChange={handleChange}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
            />
            { query.length > 0 && (
                <button data-testid="search-form-dismiss" type="button" className={styles.button} onClick={onDismiss}>
                    <IconCross className={styles.cross} />
                </button>
            ) }
        </form>
    );
    /* eslint-enable react/jsx-no-bind */
};

SearchForm.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    label: '',
    // eslint-disable-next-line react/default-props-match-prop-types
    onChange() {},
    // eslint-disable-next-line react/default-props-match-prop-types
    onDismiss() {}
};

export default SearchForm;
