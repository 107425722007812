// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconChevronRight = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polygon points="7.296 3.936 12.349 10 7.296 16.064 8.064 16.704 13.651 10 8.064 3.296" />
    </svg>
);

IconChevronRight.defaultProps = {
    size: 20
};

export default IconChevronRight;
