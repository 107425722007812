// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconTriangleDown = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polyline fillRule="evenodd" points="13 8 10 12 7 8" />
    </svg>
);

IconTriangleDown.defaultProps = {
    size: 20
};

export default IconTriangleDown;
