// @flow strict
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';

const formatDate = (date: Date): string => format(date, 'd MMMM yyyy', { locale });

const coerceToValidDate = (rawDate: ?string): Date | null => {
    // eslint-disable-next-line no-undefined
    if (rawDate === null || rawDate === undefined) {
        return null;
    }

    const date = new Date(rawDate);

    if (isNaN(date.getTime())) {
        return null;
    }

    return date;
};

function dateRangeToString(rawStartDate: ?string, rawEndDate: ?string): string {
    const startDate = coerceToValidDate(rawStartDate);
    const endDate = coerceToValidDate(rawEndDate);

    if (startDate === null && endDate === null) {
        return '';
    }

    if (startDate === null && endDate !== null) {
        return formatDate(endDate);
    }

    if (startDate !== null && endDate === null) {
        return formatDate(startDate);
    }

    if (startDate !== null && endDate !== null) {
        const equals = [
            startDate.getFullYear() === endDate.getFullYear(),
            startDate.getMonth() === endDate.getMonth(),
            startDate.getDate() === endDate.getDate()
        ].every(Boolean);

        if (equals) {
            return formatDate(startDate);
        }

        if (startDate.getMonth() === endDate.getMonth()) {
            return `${startDate.getDate()}—${formatDate(endDate)}`;
        }

        if (startDate.getFullYear() === endDate.getFullYear()) {
            return `${format(startDate, 'd MMMM', { locale })} — ${formatDate(endDate)}`;
        }

        return `${formatDate(startDate)} — ${formatDate(endDate)}`;
    }

    return '';
}

export default dateRangeToString;
