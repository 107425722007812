// @flow strict
const roundState = {
    RESULTS: 'Results',
    WAITING_REWARDING: 'WaitingRewarding',
    PASSED_TO_NEXT_ROUND: 'PassedToNextRound'
};

export type TRoundState = $Values<typeof roundState>;

export default roundState;
