// @flow strict
import * as React from 'react'; // eslint-disable-line import/no-namespace
import styles from './styles.css';

type TProps = {|
    label: string,
    children: React.Node
|};

const Definition = ({ label, children }: TProps) => (
    <div className={styles.definition}>
        <div className={styles.label}>{ label }</div>
        <div className={styles.children}>{ children }</div>
    </div>
);

export default Definition;
