// @flow strict
import * as React from 'react'; // eslint-disable-line import/no-namespace
import styles from './styles.css';

export type THatch = {|
    children: React.Node
|};

const Hatch = ({ children }: THatch) => (
    <div className={styles.container} data-testid="component.hatch">
        { children }
    </div>
);

export default Hatch;
