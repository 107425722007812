// @flow strict
// eslint-disable-next-line import/no-namespace
import * as React from 'react';

export type TTab = {|
    className?: ?string,
    name: string, // eslint-disable-line react/no-unused-prop-types
    component: React.Node, // eslint-disable-line react/no-unused-prop-types
    children: React.Node
|};

const Tab = ({ className, children }: TTab) => (
    <div className={className}>
        { children }
    </div>
);

Tab.defaultProps = {
    className: null
};

export default Tab;
