// @flow strict
import React from 'react';
import cls from 'classnames';
import styles from './index.css';

const NotFound = ({ text, className, style }: {text: string, className?: string, style?: any}) => <div className={cls([className, styles.container])} style={style}>{text}</div>;

NotFound.defaultProps = {
    className: null,
    style: null
};

export default NotFound;
