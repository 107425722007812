// @flow strict
import React from 'react';
import competitorType, { type TCompetitorType } from '../../enums/competitor-type';
import styles from './styles.css';

type TProps = {|
    competitorType: TCompetitorType
|};

const GroupResultsFooter = (props: TProps) => (
    <div className={styles.footer}>
        <div className={styles.place}>
            Место
        </div>
        <div className={styles.number}>
            №
        </div>
        <div className={styles.competitors}>
            Участники
        </div>
        { props.competitorType !== competitorType.ENSEMBLE && (
            <>
                <div className={styles.classFooter}>
                    Класс
                </div>
                <div className={styles.classPosition}>
                    Место в классе
                </div>
                <div className={styles.points}>
                    Очки
                </div>
            </>
        ) }
        <div className={styles.locationFooter}>
            Клуб, город
        </div>
        <div className={styles.coachesFooter}>
            Тренеры
        </div>
    </div>
);

export default GroupResultsFooter;
