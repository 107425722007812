// @flow strict
import React from 'react';
import type { TIcon } from '../../types/icon';

const IconChevronDown = ({ className, size }: TIcon) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polygon points="16.037 6.822 9.973 11.876 3.909 6.822 3.269 7.591 9.973 13.178 16.678 7.591" />
    </svg>
);

IconChevronDown.defaultProps = {
    size: 20
};

export default IconChevronDown;
