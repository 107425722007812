// @flow strict
import type { TCompetitorType } from '../enums/competitor-type';
import roundType, { type TRoundType } from '../enums/round-type';
import roundState, { type TRoundState } from '../enums/round-state';
import type { TRawFullName } from '../types/full-name';
import type {
    TClassPosition,
    TGroupResults,
    TGroupResultsAthlete,
    TGroupResultsCompetitor
} from '../types/group-results';
import valueToString from './value-to-string';
import fullNameToString from './full-name-to-string';

export type TRawClassPosition = {
    position: ?number,
    total: ?number
};

export type TRawGroupResultsAthlete = {
    athlete: ?{
        fullName: ?TRawFullName
    },
    class: ?string,
    classPosition: TRawClassPosition | null,
    points: ?number
};

export type TRawGroupResultsCompetitor = {
    number: ?number,
    place: ?number,
    roundType: TRoundType,
    roundState: TRoundState,
    club: ?string,
    city: ?string,
    coaches: ?Array<string>,
    ensembleName: ?string,
    composition: ?string,
    athletes: ?Array<TRawGroupResultsAthlete>
};

export type TRawGroupResults = {
    group: ?string,
    groupNumber?: ?number,
    competitorType: ?TCompetitorType,
    competitors: ?Array<TRawGroupResultsCompetitor>
};

function responseToClassPosition(classPosition: ?TRawClassPosition): TClassPosition | null {
    // eslint-disable-next-line no-undefined
    if (classPosition === null || classPosition === undefined) {
        return null;
    }

    return {
        total: classPosition.total ?? 0,
        position: classPosition.position ?? 0
    };
}

function responseToAthlete(athlete: TRawGroupResultsAthlete): TGroupResultsAthlete {
    return {
        fullName: fullNameToString(athlete?.athlete?.fullName),
        class: athlete?.class ?? null,
        classPosition: responseToClassPosition(athlete?.classPosition),
        points: athlete?.points ?? null
    };
}

function responseToCompetitor(competitor: TRawGroupResultsCompetitor): TGroupResultsCompetitor {
    return {
        number: competitor?.number ?? 0,
        place: competitor?.place ?? null,
        roundType: competitor?.roundType ?? roundType.ONE_256,
        roundState: competitor?.roundState ?? roundState.RESULTS,
        club: competitor?.club ?? null,
        city: competitor?.city ?? null,
        ensembleName: valueToString(competitor?.ensembleName),
        composition: valueToString(competitor?.composition),
        coaches: competitor?.coaches ?? [],
        athletes: (competitor?.athletes ?? []).map(responseToAthlete)
    };
}

function responseToGroupResults(response: ?TRawGroupResults): TGroupResults {
    const competitors = (response?.competitors ?? []).map(responseToCompetitor);

    let round;

    const rounds = competitors.reduce((acc, competitor) => {
        round = acc.find(x => x.roundType === competitor.roundType);

        if (round) {
            round.competitors.push(competitor);

            return acc;
        }

        return [
            ...acc,
            {
                roundType: competitor.roundType,
                roundState: competitor.roundState,
                competitors: [competitor]
            }
        ];
    }, []);

    return {
        groupName: valueToString(response?.group),
        groupNumber: response?.groupNumber ?? null,
        competitorType: response?.competitorType ?? 'Couple',
        rounds
    };
}

export default responseToGroupResults;
