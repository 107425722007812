// @flow strict
import React from 'react';
import styles from './styles.css';

const ScheduleByRoundFooter = () => (
    <div className={styles.footer}>
        <div className={styles.range}>
            Начало—конец
        </div>
        <div className={styles.beginning}>
            Начало
        </div>
        <div className={styles.round}>
            Тур
        </div>
        <div className={styles.floors}>
            Паркет
        </div>
        <div className={styles.group}>
            Группа
        </div>
        <div className={styles.dances}>
            Программа
        </div>
        <div className={styles.competitors}>
            Участники
        </div>
        <div className={styles.heatsFooter}>
            Заходы
        </div>
        <div className={styles.resultsFooter}>
            Результаты
        </div>
    </div>
);

export default ScheduleByRoundFooter;
