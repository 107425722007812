// @flow strict
import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './history';
import './index.css'; // eslint-disable-line import/no-unassigned-import

const element = document.getElementById('app');

if (element !== null) {
    render(
        <Router history={history}>
            <Routes />
        </Router>,
        element
    );

    if (PROJECT_ENV === 'development' && module.hot) {
        module.hot.accept('./routes', () => {
            const NextRoutes = require('./routes').default;

            render(
                <Router history={history}>
                    <NextRoutes />
                </Router>,
                element
            );
        });
    }
}
