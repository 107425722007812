// @flow strict
// eslint-disable-next-line import/no-namespace
import * as React from 'react';
import cls from 'classnames';
import Tab from './tab';
import styles from './styles.css';

export type TTabs = {|
    className?: ?string,
    children: Array<React.Element<typeof Tab>>,
    activeTab: string
|};

const Tabs = ({ className, children, activeTab }: TTabs) => {
    const [tab, setTab] = React.useState(activeTab);

    return (
        <div className={className}>
            <header className={styles.header}>
                { children.map(child => (
                    <div
                        key={`tab-heading-${child.props.name}`}
                        className={cls(styles.heading, child.props.name === tab ? styles.isActive : null)}
                        role="button"
                        tabIndex="-1"
                        onClick={() => setTab(child.props.name)}
                    >
                        { child.props.component }
                    </div>
                )) }
            </header>
            { children.map(child => (
                <div key={`tab-pane-${child.props.name}`} className={child.props.name !== tab ? styles.isHidden : null} hidden={child.props.name !== tab}>
                    { child }
                </div>
            )) }
        </div>
    );
};

Tabs.Tab = Tab;

Tabs.defaultProps = {
    className: null
};

export { Tab };

export default Tabs;
