// @flow strict
const roundStage = {
    CURRENT: 'Current',
    PAST: 'Past',
    FUTURE: 'Future'
};

export type TRoundStage = $Values<typeof roundStage>;

export default roundStage;
