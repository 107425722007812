// @flow strict
import React from 'react';
import cls from 'classnames';
import competitorType, { type TCompetitorType } from '../../enums/competitor-type';
import type { TRoundHeatsCompetitor } from '../../types/round-heats';
import IconMusic from '../icon-music';
import styles from './styles.css';

type TProps = {|
    ...TRoundHeatsCompetitor,
    competitorType: TCompetitorType
|};

const RoundHeatsItem = (props: TProps) => {
    const heats = (
        <div className={styles.heats}>
            { props.dances.map(dance => (
                <div key={`round-heats-item-${props.number}-dance-${dance.name}`} className={styles.heat}>
                    <strong>{ dance.name }</strong> { dance.heat }
                </div>
            )) }
        </div>
    );

    return (
        <div key={`round-heats-item-${props.number}`} className={styles.item}>
            <div className={cls(styles.number, styles.numberCell, styles.cell, props.competitorType === competitorType.ENSEMBLE && styles.underscore)}>
                { props.number }
            </div>
            { props.competitorType === competitorType.ENSEMBLE && (
                <div className={cls(styles.competitors, styles.cell, styles.underscore)}>
                    { props.ensembleName.length > 0 && (
                        <div>
                            { props.ensembleName }
                        </div>
                    ) }
                    { props.composition.length > 0 && (
                        <div className={styles.composition}>
                            <IconMusic />
                            <span>{ props.composition }</span>
                        </div>
                    ) }
                    <div className={styles.athletes}>
                        { props.athletes.map((athlete, index) => (
                            <div key={`round-heats-item-${props.number}-athlete-${index}`} className={styles.athlete}>
                                { athlete }
                            </div>
                        )) }
                    </div>
                    { heats }
                </div>
            ) }
            { props.competitorType === competitorType.SOLO && (
                <div className={cls(styles.competitors, styles.cell)}>
                    <span className={styles.athlete}>
                        { props.athletes[0] }
                    </span>
                    { heats }
                </div>
            ) }
            { props.competitorType === competitorType.COUPLE && (
                <div className={cls(styles.competitors, styles.cell)}>
                    <span className={styles.athlete}>
                        <span className={styles.athlete}>{ props.athletes[0] }</span>
                        <span className={styles.and}>—</span>
                        <span className={styles.athlete}>{ props.athletes[1] }</span>
                    </span>
                    { heats }
                </div>
            ) }
            { props.dances.map(dance => (
                <div
                    key={`round-heats-item-${props.number}-dance-column-${dance.name}`}
                    className={cls(styles.heatCell, styles.cell, props.competitorType === competitorType.ENSEMBLE && styles.underscore)}
                >
                    { dance.heat }
                </div>
            )) }
        </div>
    );
};

export default RoundHeatsItem;
