// @flow strict
import React from 'react';
import bronze from './assets/bronze.svg';
import gold from './assets/gold.svg';
import silver from './assets/silver.svg';
import simple from './assets/simple.svg';
import styles from './styles.css';

export type TPlace = {|
    value: number | null,
    isFinalist?: boolean
|};

const Place = ({ value, isFinalist }: TPlace) => {
    if (value === null) {
        return (
            <div className={styles.container}>·</div>
        );
    }

    if (value === 1) {
        return (
            <div className={styles.container}>
                <img src={gold} className={styles.medal} alt="1 место" />
                <div className={styles.value}>
                    <strong>{ value }</strong>
                </div>
            </div>
        );
    }

    if (value === 2) {
        return (
            <div className={styles.container}>
                <img src={silver} className={styles.medal} alt="2 место" />
                <div className={styles.value}>
                    <strong>{ value }</strong>
                </div>
            </div>
        );
    }

    if (value === 3) {
        return (
            <div className={styles.container}>
                <img src={bronze} className={styles.medal} alt="3 место" />
                <div className={styles.value}>
                    <strong>{ value }</strong>
                </div>
            </div>
        );
    }

    if (isFinalist) {
        return (
            <div className={styles.container}>
                <img src={simple} className={styles.medal} alt={`${value} место`} />
                <div className={styles.value}>
                    <strong>{ value }</strong>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <strong>{ value }</strong>
        </div>
    );
};

Place.defaultProps = {
    isFinalist: false
};

export default Place;
