// @flow strict
import type { TCompetitionDetails } from '../types/competition-details';
import responseToVideos, { type TRawVideos } from './response-to-videos';
import valueToString from './value-to-string';
import dateRangeToString from './date-range-to-string';

export type TRawCompetitionDetails = {
    title?: string, // Название
    classes?: Array<string>, // Классы
    owner?: string, // Организатор
    city?: string, // Город
    location?: string, // Адрес + место
    place?: string, // Место
    startDate?: string, // Дата начала
    endDate?: string, // Дата конца
    videos?: TRawVideos
};

function responseToCompetitionDetails(details: ?TRawCompetitionDetails): TCompetitionDetails {
    return {
        title: valueToString(details?.title),
        rank: (details?.classes ?? []).filter(Boolean).join(' · '),
        owner: valueToString(details?.owner),
        location: [
            valueToString(details?.city),
            valueToString(details?.location)
        ].filter(x => x.length > 0).join(', '),
        date: dateRangeToString(details?.startDate, details?.endDate),
        videos: responseToVideos(details?.videos)
    };
}

export default responseToCompetitionDetails;
