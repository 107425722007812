// @flow strict
import React, { useState } from 'react';
import filterScheduleByGroup from '../../filters/filter-schedule-by-group';
import type { TScheduleByGroupItem } from '../../types/schedule';
import NotFound from '../not-found';
import Footer from './footer';
import Header from './header';
import Item from './item';

type TScheduleByGroupList = {|
    className?: string,
    competitionId: number,
    items: Array<TScheduleByGroupItem>
|};

const ScheduleByGroupList = ({ className, competitionId, items }: TScheduleByGroupList) => {
    const [query, setQuery] = useState('');

    let filteredItems = items;
    const trimmedQuery = query.trim();

    if (trimmedQuery.length > 0 && items.length > 0) {
        filteredItems = filterScheduleByGroup(items, trimmedQuery);
    }

    return (
        <div className={className}>
            <Header query={query} onSearch={setQuery} />
            { (trimmedQuery.length > 0 && filteredItems.length === 0)
                && <NotFound text="Группы не найдены" style={{ margin: '1rem 0' }} />
            }
            { filteredItems.map(item => (
                <Item
                    key={`schedule-by-group-item-${item.id}`}
                    {...item}
                    competitionId={competitionId}
                />
            )) }
            {(trimmedQuery.length === 0 && filteredItems.length > 0)
                && <Footer />
            }
        </div>
    );
};

ScheduleByGroupList.defaultProps = {
    className: null
};

export default ScheduleByGroupList;
