// @flow strict
import axios from 'axios';
import { useEffect, useState } from 'react';
import type { TSchedule } from '../types/schedule';
import { responseToSchedule } from '../mappers';

function getInitialState(): TSchedule {
    return {
        details: null,
        groups: [],
        days: [],
        halls: []
    };
}

function useScheduleApi() {
    const [schedule, setSchedule] = useState(getInitialState());
    // eslint-disable-next-line
    const [competitionId, setCompetitionId] = useState<number>(0);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');

    const url = `/api/competitions/${competitionId}/schedule`;

    useEffect(() => {
        async function fetch() {
            setIsFetching(true);

            try {
                const { data } = await axios.get(url);

                setSchedule(responseToSchedule(data));
                setError('');
            } catch (err) {
                setError('Ошибка при получении расписания');
            } finally {
                setIsFetching(false);
            }
        }

        if (competitionId !== 0) {
            fetch();
        }
    }, [competitionId]);

    return [schedule, isFetching, error, setCompetitionId];
}

export default useScheduleApi;
