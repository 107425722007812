// @flow strict
import competitorType, { type TCompetitorType } from '../enums/competitor-type';
import roundType, { type TRoundType } from '../enums/round-type';
import type { TRoundHeats, TRoundHeatsCompetitor } from '../types/round-heats';
import type { TRawFullName } from '../types/full-name';
import fullNameToString from './full-name-to-string';
import valueToString from './value-to-string';

export type TRawRoundHeatsCompetitor = {
    number: ?number,
    ensembleName?: ?string,
    composition?: ?string,
    dances?: ?Array<{
        name?: ?string,
        heat?: ?number
    }>,
    athletes?: ?Array<{
        fullName?: ?TRawFullName
    }>
};

export type TRawRoundHeats = {
    group?: ?string,
    groupNumber?: ?number,
    roundType?: ?TRoundType,
    markCount?: number,
    competitorCount?: number,
    competitorsPerHeat?: number,
    heatCount: number,
    competitorType?: ?TCompetitorType,
    competitors?: ?Array<TRawRoundHeatsCompetitor>
};

function responseToCompetitor(competitor: ?TRawRoundHeatsCompetitor): TRoundHeatsCompetitor {
    return {
        number: competitor?.number ?? 0,
        ensembleName: valueToString(competitor?.ensembleName),
        composition: valueToString(competitor?.composition),
        athletes: (competitor?.athletes ?? []).map(athlete => fullNameToString(athlete?.fullName)),
        dances: (competitor?.dances ?? []).map(dance => ({
            name: valueToString(dance?.name),
            heat: dance?.heat ?? 0
        }))
    };
}

function responseToRoundHeats(response: ?TRawRoundHeats): TRoundHeats {
    const competitors = (response?.competitors ?? []).map(responseToCompetitor);

    let dances = [];

    if (competitors.length > 0) {
        dances = (competitors[0].dances || []).map(d => d.name);
    }

    return {
        groupName: valueToString(response?.group),
        groupNumber: response?.groupNumber ?? null,
        roundType: response?.roundType ?? roundType.ONE_256,
        markCount: response?.markCount ?? 0,
        competitorCount: response?.competitorCount ?? 0,
        competitorsPerHeat: response?.competitorsPerHeat ?? 0,
        heatCount: response?.heatCount ?? 0,
        competitorType: response?.competitorType ?? competitorType.COUPLE,
        competitors,
        dances
    };
}

export default responseToRoundHeats;
