// @flow strict
import React from 'react';
import cls from 'classnames';
import styles from './styles.css';

export type TToggle = {|
    // eslint-disable-next-line react/require-default-props
    className?: string,
    label?: string,
    name: string,
    isChecked?: boolean,
    isDisabled?: boolean,
    onChange: (isChecked: boolean) => void
|};

const Toggle = ({ className, name, label, isChecked, isDisabled, onChange }: TToggle) => {
    function handleChange(event) {
        onChange(event.target.checked);
    }

    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className={cls(styles.container, className, isDisabled && styles.isDisabled)}>
            <input
                data-testid="components.toggle.input"
                className={styles.input}
                type="checkbox"
                name={name}
                checked={isChecked}
                disabled={isDisabled}
                onChange={handleChange}
            />
            <div className={cls(styles.frame, isChecked && styles.isChecked)} />
            <div className={styles.label}>{ label }</div>
        </label>
    );
};

Toggle.defaultProps = {
    label: '',
    isChecked: false,
    isDisabled: false
};

export default Toggle;
