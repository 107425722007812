// @flow strict
import * as React from 'react'; // eslint-disable-line import/no-namespace
import styles from './styles.css';

export type TContainer = {|
    children: React.Node
|};

const Container = ({ children }: TContainer) => (
    <div className={styles.container} data-testid="component.container">
        { children }
    </div>
);

export default Container;
