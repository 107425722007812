// @flow strict
import React from 'react';
import appStore from './assets/app-store.svg';
import googlePlay from './assets/google-play.svg';
import styles from './styles.css';

const Header = () => (
    <header className={styles.header}>
        <div className={styles.container}>
            <div className={styles.left}>
                <a href="https://fdsarr.ru/dance/" className={styles.logo}>
                    <span>Танцевальный</span>
                    <span>спорт</span>
                </a>
                <a href="https://fdsarr.ru/dance/competitions/" target="_blank" rel="noopener noreferrer" className={styles.calendar}>
                    Календарь соревнований
                </a>
            </div>
            <div className={styles.right}>
                <div className={styles.text}>
                    Скачай приложение с расписанием <br /> и уведомлениями о результатах
                </div>
                <div className={styles.stores}>
                    <a href="https://apps.apple.com/app/id1447210742" className={styles.storeLink} target="_blank" rel="noopener noreferrer">
                        <img src={appStore} alt="Доступно в App Store" className={styles.storeImage} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=ru.vftsarr.dancesport" className={styles.storeLink} target="_blank" rel="noopener noreferrer">
                        <img src={googlePlay} alt="Скачайте в Google Play" className={styles.storeImage} />
                    </a>
                </div>
            </div>
        </div>
    </header>
);

export default Header;
