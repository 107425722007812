// @flow strict
const roundType = {
    ONE_256: 'One256',
    ONE_256_REDANCE: 'One256Redance',
    ONE_128: 'One128',
    ONE_128_REDANCE: 'One128Redance',
    ONE_64: 'One64',
    ONE_64_REDANCE: 'One64Redance',
    ONE_32: 'One32',
    ONE_32_REDANCE: 'One32Redance',
    ONE_16: 'One16',
    ONE_16_REDANCE: 'One16Redance',
    ONE_8: 'One8',
    ONE_8_REDANCE: 'One8Redance',
    ONE_4: 'One4',
    ONE_4_REDANCE: 'One4Redance',
    ONE_2: 'One2',
    ONE_2_REDANCE: 'One2Redance',
    FINAL: 'Final'
};

export const labels = {
    [roundType.ONE_256]: '1/256 финала',
    [roundType.ONE_256_REDANCE]: '1/256 финала Redance',
    [roundType.ONE_128]: '1/128 финала',
    [roundType.ONE_128_REDANCE]: '1/128 финала Redance',
    [roundType.ONE_64]: '1/64 финала',
    [roundType.ONE_64_REDANCE]: '1/64 финала Redance',
    [roundType.ONE_32]: '1/32 финала',
    [roundType.ONE_32_REDANCE]: '1/32 финала Redance',
    [roundType.ONE_16]: '1/16 финала',
    [roundType.ONE_16_REDANCE]: '1/16 финала Redance',
    [roundType.ONE_8]: '1/8 финала',
    [roundType.ONE_8_REDANCE]: '1/8 финала Redance',
    [roundType.ONE_4]: 'Четвертьфинал',
    [roundType.ONE_4_REDANCE]: 'Четвертьфинал Redance',
    [roundType.ONE_2]: 'Полуфинал',
    [roundType.ONE_2_REDANCE]: 'Полуфинал Redance',
    [roundType.FINAL]: 'Финал'
};

export const shortLabels = {
    [roundType.ONE_256]: '1/256',
    [roundType.ONE_256_REDANCE]: '1/256 R',
    [roundType.ONE_128]: '1/128',
    [roundType.ONE_128_REDANCE]: '1/128 R',
    [roundType.ONE_64]: '1/64',
    [roundType.ONE_64_REDANCE]: '1/64 R',
    [roundType.ONE_32]: '1/32',
    [roundType.ONE_32_REDANCE]: '1/32 R',
    [roundType.ONE_16]: '1/16',
    [roundType.ONE_16_REDANCE]: '1/16 R',
    [roundType.ONE_8]: '1/8',
    [roundType.ONE_8_REDANCE]: '1/8 R',
    [roundType.ONE_4]: '1/4',
    [roundType.ONE_4_REDANCE]: '1/4 R',
    [roundType.ONE_2]: '1/2',
    [roundType.ONE_2_REDANCE]: '1/2 R',
    [roundType.FINAL]: 'Ф'
};

export type TRoundType = $Values<typeof roundType>;

export default roundType;
