// @flow strict
import { type TCompetitorType } from '../enums/competitor-type';
import { type TRoundType } from '../enums/round-type';
import { type TRoundStage } from '../enums/round-stage';
import scheduleGroupState, { type TScheduleGroupState } from '../enums/schedule-group-state';
import roundResultsState, { type TRoundResultsState } from '../enums/round-results-state';
import type {
    TSchedule,
    TScheduleByGroupItem, TScheduleByRoundItem,
    TScheduleCurrentRound, TScheduleDance,
    TScheduleDay
} from '../types/schedule';
import responseToCompetitionDetails, { type TRawCompetitionDetails } from './response-to-competition-details';
import valueToString from './value-to-string';

export type TRawScheduleCurrentRound = {
    type: TRoundType,
    roundId: number
};

export type TRawScheduleDance = {
    group: ?string,
    groupId: ?number,
    groupNumber?: ?number,
    roundType: TRoundType,
    roundId: ?number,
    dances: ?Array<string>,
    competitorCount: ?number,
    competitorType: TCompetitorType,
    heatCount: ?number,
    competitorsPerHeat: ?number,
    passedCount: ?number,
    resultsState: ?TRoundResultsState
};

export type TRawScheduleByRoundItem = {
    id: number,
    startTime: string,
    finishTime: string,
    floors: ?Array<string>,
    stage: ?TRoundStage,
    description: string,
    dance: ?TRawScheduleDance,
    hallId: number
};

export type TRawScheduleByGroupItem = {
    id: number,
    name?: ?string,
    number?: ?number,
    state?: ?TScheduleGroupState,
    currentRound?: ?TRawScheduleCurrentRound,
    competitorsCount?: ?number,
    remainingCount?: ?number
};

export type TRawScheduleDay = {
    date: string,
    isCurrent?: ?boolean,
    items: ?Array<TRawScheduleByRoundItem>
};

export type TRawScheduleHall = {|
    id: number,
    name: string
|};

export type TRawSchedule = {
    competition: ?TRawCompetitionDetails,
    groups: ?Array<TRawScheduleByGroupItem>,
    days: ?Array<TRawScheduleDay>,
    halls: ?Array<TRawScheduleHall>
};

function responseToCurrentRound(round: ?TRawScheduleCurrentRound): TScheduleCurrentRound | null {
    // eslint-disable-next-line no-undefined
    if (round === null || round === undefined) {
        return null;
    }

    return {
        type: round?.type,
        roundId: round?.roundId ?? 0
    };
}

function responseToDance(dance: ?TRawScheduleDance): TScheduleDance | null {
    // eslint-disable-next-line no-undefined
    if (dance === null || dance === undefined) {
        return null;
    }

    return {
        groupId: dance?.groupId ?? 0,
        groupName: valueToString(dance?.group),
        groupNumber: dance?.groupNumber ?? null,
        roundType: dance.roundType,
        roundId: dance?.roundId ?? 0,
        resultsState: dance?.resultsState ?? roundResultsState.NONE,
        dances: (dance?.dances ?? []).join(' '),
        competitorCount: dance?.competitorCount ?? 0,
        competitorType: dance.competitorType,
        heatCount: dance?.heatCount ?? 0,
        competitorsPerHeat: dance?.competitorsPerHeat ?? 0,
        passedCount: dance?.passedCount ?? 0
    };
}

function responseToRoundItem(round: ?TRawScheduleByRoundItem): TScheduleByRoundItem {
    return {
        id: round?.id ?? 0,
        startTime: valueToString(round?.startTime).replace(/:\d{2}$/, ''),
        finishTime: valueToString(round?.finishTime).replace(/:\d{2}$/, ''),
        dance: responseToDance(round?.dance),
        description: valueToString(round?.description),
        floors: (round?.floors ?? []).join(' '),
        stage: valueToString(round?.stage),
        hallId: round ? round?.hallId : 0
    };
}

function responseToGroupItem(group: ?TRawScheduleByGroupItem): TScheduleByGroupItem {
    return {
        id: group?.id ?? 0,
        name: valueToString(group?.name),
        number: group?.number ?? null,
        competitorsCount: group?.competitorsCount ?? 0,
        remainingCount: group?.remainingCount ?? 0,
        currentRound: responseToCurrentRound(group?.currentRound),
        state: group?.state ?? scheduleGroupState.READY
    };
}

function responseToDay(day: TRawScheduleDay): TScheduleDay {
    return {
        date: new Date(day.date),
        isCurrent: day.isCurrent ?? false,
        items: (day.items ?? []).map(responseToRoundItem)
    };
}

function responseToSchedule(response: ?TRawSchedule): TSchedule {
    return {
        details: responseToCompetitionDetails(response?.competition),
        groups: (response?.groups ?? []).map(responseToGroupItem),
        days: (response?.days ?? []).map(responseToDay),
        halls: response?.halls ?? []
    };
}

export default responseToSchedule;
