// @flow strict
import React from 'react';
import cls from 'classnames';
import type { TScheduleByGroupItem } from '../../types/schedule';
import { shortLabels } from '../../enums/round-type';
import scheduleGroupState from '../../enums/schedule-group-state';
import { pluralize } from '../../utilities';
import styles from './styles.css';

type TProps = {|
    ...TScheduleByGroupItem,
    competitionId: number
|};

const ScheduleByGroupItem = ({ id, name, number, currentRound, state, competitorsCount, remainingCount, competitionId }: TProps) => {
    const heats = currentRound !== null ? <a href={`/competitions/${competitionId}/rounds/${currentRound.roundId}`}>заходы</a> : '·';
    const round = currentRound !== null ? <strong>{ shortLabels[currentRound.type] }</strong> : '·';
    
    return (
        <div className={cls(styles.item, state === scheduleGroupState.COMPLETED ? styles.completed : null)} data-testid="component.ScheduleByGroupItem.item">
            <div className={styles.groupContainer}>
                <div className={styles.group}>
                    <div className={styles.number}>
                        { number !== null
                            ? <strong>{ number }</strong>
                            : '·'
                        }
                    </div>
                    <div className={styles.name}>
                        { name }
                    </div>
                </div>
                <div className={styles.roundType}>{ round }</div>
                <div className={styles.heats}>{ heats }</div>
                <div className={styles.results}>
                    { state === scheduleGroupState.READY && `${competitorsCount} ${pluralize(competitorsCount, 'участник', 'участника', 'участников')}` }
                    { [scheduleGroupState.IN_PROGRESS, scheduleGroupState.WAITING_REWARDING, scheduleGroupState.COMPLETED].some(s => s === state) && (
                        <a href={`/competitions/${competitionId}/results/${id}`}>
                            { state === scheduleGroupState.IN_PROGRESS && `осталось ${remainingCount} из ${competitorsCount}` }
                            { state === scheduleGroupState.WAITING_REWARDING && `${remainingCount} из ${competitorsCount} ждут награждения` }
                            { state === scheduleGroupState.COMPLETED && `${competitorsCount} закончили выступления` }
                        </a>
                    ) }
                </div>
            </div>
            { currentRound !== null && (
                <div className={styles.roundContainer}>
                    <div className={styles.roundName}>текущий тур { round }</div>
                    <div className={styles.results}>{ heats }</div>
                </div>
            ) }
        </div>
    );
};

export default ScheduleByGroupItem;
