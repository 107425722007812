// @flow strict
import type { TRawFullName } from '../types/full-name';

function fullNameToString(fullName: ?TRawFullName): string {
    if (fullName) {
        return [fullName.lastName, fullName.firstName].filter(Boolean).join(' ');
    }

    return '';
}

export default fullNameToString;
