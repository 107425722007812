// @flow strict
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GroupResultsList } from '../../components/group-results';
import useGroupResultsApi from '../../hooks/use-group-results-api';
import styles from './styles.css';

export type TGroupResultsPage = {|
    match: ?{|
        params: ?{|
            competitionId: ?string,
            groupId: ?string
        |}
    |}
|};

const GroupResultsPage = ({ match }: TGroupResultsPage) => {
    const [results, isFetching, error, fetch] = useGroupResultsApi();

    const competitionId = parseInt(match?.params?.competitionId ?? '', 10);
    const groupId = parseInt(match?.params?.groupId ?? '', 10);

    const heading = (
        <>
            <Link to={`/competitions/${competitionId}/schedule`}>назад к расписанию</Link>
            <h1 className={styles.heading}>Результаты</h1>
        </>
    );

    useEffect(() => {
        document.title = `Результаты · ${document.title}`;
    }, []);

    useEffect(() => {
        fetch(competitionId, groupId);
    }, [competitionId, groupId]);

    if (isFetching) {
        return (
            <>
                { heading }
                <h3>Загрузка...</h3>
            </>
        );
    }

    if (error.length > 0) {
        return (
            <>
                { heading }
                <h3>{ error }</h3>
            </>
        );
    }

    return (
        <>
            { heading }
            <h3 className={styles.subheading}>
                { results.groupNumber !== null ? `${results.groupNumber} · ${results.groupName}` : results.groupName }
            </h3>
            <GroupResultsList
                competitorType={results.competitorType}
                rounds={results.rounds}
            />
        </>
    );
};

export default GroupResultsPage;
