// @flow strict
import React, { useState } from 'react';
import filterRoundHeats from '../../filters/filter-round-heats';
import sortDirection from '../../enums/sort-direction';
import type { TCompetitorType } from '../../enums/competitor-type';
import type { TRoundHeatsCompetitor } from '../../types/round-heats';
import Footer from './footer';
import Header from './header';
import Item from './item';

type TRoundHeatsList = {|
    competitorType: TCompetitorType,
    dances: Array<string>,
    competitors: Array<TRoundHeatsCompetitor>
|};

const RoundHeatsList = ({ competitorType, dances, competitors }: TRoundHeatsList) => {
    const [query, setQuery] = useState('');
    const [direction, setDirection] = useState(sortDirection.ASC);

    let filteredCompetitors = competitors;
    const trimmedQuery = query.trim();

    if (trimmedQuery.length > 0 && competitors.length > 0) {
        filteredCompetitors = filterRoundHeats(competitors, trimmedQuery);
    }

    function handleChangeDirection() {
        setDirection(direction === sortDirection.ASC ? sortDirection.DESC : sortDirection.ASC);
    }

    return (
        <>
            <Header
                dances={dances}
                query={query}
                sortDirection={direction}
                onSort={handleChangeDirection}
                onSearch={setQuery}
            />
            { filteredCompetitors
                .sort((a, b) => direction === sortDirection.ASC ? a.number - b.number : b.number - a.number)
                .map(competitor => (
                    <Item
                        key={`round-heats-competitor-${competitor.number}`}
                        {...competitor}
                        competitorType={competitorType}
                    />
                )) }
            <Footer
                dances={dances}
                sortDirection={direction}
                onSort={handleChangeDirection}
            />
        </>
    );
};

export default RoundHeatsList;
