// @flow strict
import type { TScheduleByRoundItem } from '../types/schedule';
import createPredicate from './create-predicate';

function filterScheduleByRound(items: Array<TScheduleByRoundItem>, query: string): Array<TScheduleByRoundItem> {
    const predicate = createPredicate(query);

    return items.filter(item => {
        if (item.dance !== null) {
            return predicate(item.dance.groupName) || predicate(String(item.dance?.groupNumber ?? ''));
        }

        return false;
    });
}

export default filterScheduleByRound;
