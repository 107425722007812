// @flow strict
import type { TGroupResultsRound } from '../types/group-results';
import createPredicate from './create-predicate';

function filterGroupResults(rounds: Array<TGroupResultsRound>, query: string): Array<TGroupResultsRound> {
    const predicate = createPredicate(query);

    let competitors;

    return rounds.reduce((acc, round) => {
        competitors = round.competitors.filter(competitor => competitor.athletes.some(athlete => predicate(athlete.fullName)) || (competitor.ensembleName.length > 0 && predicate(competitor.ensembleName)) || predicate(String(competitor.number)));

        if (competitors.length > 0) {
            return [
                ...acc,
                {
                    roundType: round.roundType,
                    roundState: round.roundState,
                    competitors
                }
            ];
        }

        return acc;
    }, []);
}

export default filterGroupResults;
