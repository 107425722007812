// @flow strict
import React from 'react';
import type { TCompetitionDetails } from '../../types/competition-details';
import Definition from '../definition';
import styles from './styles.css';

const CompetitionDetails = (props: TCompetitionDetails) => (
    <>
        { props.title.length > 0 && (
            <Definition label="Соревнование">
                <div className={styles.description}>
                    { props.title }
                </div>
            </Definition>
        ) }
        { props.rank.length > 0 && (
            <Definition label="Ранг">
                { props.rank }
            </Definition>
        ) }
        { props.owner.length > 0 && (
            <Definition label="Организатор">
                { props.owner }
            </Definition>
        ) }
        { props.location.length > 0 && (
            <Definition label="Место проведения">
                <div className={styles.description}>
                    { props.location }
                </div>
            </Definition>
        ) }
        { props.date.length > 0 && (
            <Definition label="Дата проведения">
                { props.date }
            </Definition>
        ) }
        { props.videos.length > 0 && (
            <Definition label="Трансляции">
                { props.videos.map((video, index) => (
                    <a
                        href={video.link}
                        className={styles.link}
                        key={`video-${index}`}
                        data-testid={`video-${index}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        { video.name }
                    </a>
                )) }
            </Definition>
        ) }
    </>
);

CompetitionDetails.defaultProps = {
    title: '',
    rank: '',
    owner: '',
    location: '',
    date: '',
    videos: []
};

export default CompetitionDetails;
