// @flow strict
import type { TScheduleByGroupItem } from '../types/schedule';
import createPredicate from './create-predicate';

function filterScheduleByGroup(items: Array<TScheduleByGroupItem>, query: string): Array<TScheduleByGroupItem> {
    const predicate = createPredicate(query);

    return items.filter(item => predicate(item.name) || predicate(String(item.number || '')));
}

export default filterScheduleByGroup;
