// @flow strict
import React from 'react';
import competitorType, { type TCompetitorType } from '../../enums/competitor-type';
import type { TGroupResultsCompetitor } from '../../types/group-results';
import IconMusic from '../icon-music';
import Place from '../place';
import styles from './styles.css';

export type TGroupResultsItem = {|
    ...TGroupResultsCompetitor,
    competitorType: TCompetitorType
|};

const GroupResultsItem = (props: TGroupResultsItem) => {
    const location = [props.city, props.club].filter(Boolean).join(', ');

    return (
        <div key={`group-results-item-${props.number}`} className={styles.item}>
            <div className={styles.competitorsContainer}>
                <div className={styles.place}>
                    <Place value={props.place} />
                </div>
                <div className={styles.number}>{ props.number }</div>
                { props.competitorType === competitorType.ENSEMBLE
                    ? (
                        <div className={styles.ensemble}>
                            { props.ensembleName.length > 0 && (
                                <div>
                                    { props.ensembleName }
                                </div>
                            ) }
                            { props.composition.length > 0 && (
                                <div className={styles.composition}>
                                    <IconMusic />
                                    <span>{ props.composition }</span>
                                </div>
                            ) }
                            <div className={styles.athletes}>
                                { props.athletes.map((athlete, index) => (
                                    <div key={`competitor-${props.number}-athlete-${index}`} data-testid="group-results.item.athlete">
                                        { athlete.fullName }
                                    </div>
                                )) }
                            </div>
                        </div>
                    )
                    : (
                        <div className={styles.competitorsList}>
                            { props.athletes.map((athlete, index) => (
                                <div key={`competitor-${props.number}-athlete-${index}`} className={styles.competitorsItem} data-testid="group-results.item.athlete">
                                    <div className={styles.competitors}>
                                        { athlete.fullName }
                                    </div>
                                    <div className={styles.class}>
                                        { athlete.class !== null ? athlete.class : '·' }
                                    </div>
                                    <div className={styles.classPosition}>
                                        { athlete.classPosition !== null ? `${athlete.classPosition.position} / ${athlete.classPosition.total}` : '·' }
                                    </div>
                                    <div className={styles.points}>
                                        { athlete.points !== null ? athlete.points.toFixed(2).replace(/0$/, '') : '·' }
                                    </div>
                                </div>
                            )) }
                        </div>
                    )
                }
            </div>
            <div className={styles.locationContainer}>
                <div className={styles.locationLabel}>Клуб</div>
                <div className={styles.locationValue}>
                    { location.length > 0 ? location : '·' }
                </div>
            </div>
            <div className={styles.coachesContainer}>
                <div className={styles.coachesLabel}>
                    { props.coaches.length > 1 ? 'Тренеры' : 'Тренер' }
                </div>
                <div className={styles.coachesValue}>
                    { props.coaches.length > 0 ? props.coaches.join(', ') : '·' }
                </div>
            </div>

        </div>
    );
};

export default GroupResultsItem;
